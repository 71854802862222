import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import AdminInfo from '../components/structure/admin-info';
import { PageMetadata } from '../components/structure/head';
import { getParagraph } from '../components/paragraphs/paragraphs';
import { InfoboxMarinePark } from '../components/paragraphs/information-box-marine-park';
import { InfoboxPlace } from '../components/paragraphs/information-box-place';
import { InfoboxShop } from '../components/paragraphs/information-box-shop';
import { InfoboxWalk } from '../components/paragraphs/information-box-walk';
import { InfoboxWaterfall } from '../components/paragraphs/information-box-waterfall';
import { MailChimpOptInFormBlocks } from '../components/blocks/mailchimp-opt-in-form';
import { MessageSomePagesBlock } from '../components/blocks/message-some-pages';
import { SiteWideMessageBlock } from '../components/blocks/site-wide-message';
import { TileImagesSomePagesBlocks } from '../components/blocks/tile-images-some-pages';
import { createPageParentTrail } from '../functions/build_menus/build-menu-tree';
import PageTitleSection from '../components/structure/page-header';
import HeroImageSlide from '../components/structure/hero-image-slide';
import Slider from 'react-slick';
import { randomKey, convertTrueFalseStringToBoolean } from '../functions/common';
import { DevAids } from '../components/dev-aids';

export default function PlaceTemplate({ data, pageContext }) {
  const park = process.env.GATSBY_PARK,
    thisPage = data.nodePlace,
    isLive = convertTrueFalseStringToBoolean(thisPage.status),
    state = thisPage.moderation_state,
    nodeID = thisPage.drupal_internal__nid,
    hideHeader = thisPage.field_hide_header_breadcrumbs,
    title = thisPage.title,
    path = pageContext?.alias || null,
    breadcrumbData = createPageParentTrail(pageContext.siteMenuData, nodeID, path) || null,
    // Get the raw HTML of the hero Block Title field, as this may contain HTML
    heroBlockTitle = thisPage.field_hero_block_title?.value || null,
    tileImageURL =
      thisPage.relationships?.field_tile_image?.customLocalFieldMediaImage?.publicURL || null,
    heroImages = thisPage.relationships.field_hero || [],
    paragraphs = thisPage.relationships.paragraphs.map((item) => getParagraph(item, pageContext)),
    infoboxData = thisPage.relationships.infobox || null,
    infoboxType =
      infoboxData?.paragraph_type?.drupal_internal__target_id.replace('information_', '') || null;

  // Hero image slider
  const sliderImages = heroImages.map((item, index: number) => (
    <HeroImageSlide
      node={item}
      slideCount={index}
      heroBlockTitle={heroBlockTitle}
      key={randomKey()}
    />
  ));

  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    pauseOnFocus: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
  };

  // Assign the appropriate Infobox component based on the data provided
  // This is specific to the Infobox types permitted for this Content Type.
  // We do this here instead of in ./functions/common.js as it must return a JSX element
  // TODO: This can probably be refactored to use the same technique as getParagraphs()
  let assignInfobox = (infoboxData, infoboxType) => {
    switch (infoboxType) {
      case 'marine_park':
        return <InfoboxMarinePark {...infoboxData} />;
      case 'place':
        return <InfoboxPlace {...infoboxData} />;
      case 'shop':
        return <InfoboxShop {...infoboxData} />;
      case 'walk':
        return <InfoboxWalk {...infoboxData} />;
      case 'waterfall':
        return <InfoboxWaterfall {...infoboxData} />;
      default:
        return false;
    }
  };

  const infobox = assignInfobox(infoboxData, infoboxType);

  return (
    <>
      <PageMetadata
        title={title}
        description={thisPage.body?.summary || title}
        thumbnailImage={tileImageURL}
        path={path}
        dateModified={thisPage.changed}
      />

      {/* If not the homepage, add the title header and social links */}
      {path !== '' && hideHeader === false ? (
        <PageTitleSection
          title={title}
          socialMediaBlock={data.blockContentSocialMedia}
          breadcrumbs={breadcrumbData}
          summaryText={thisPage?.body?.summary || null}
        />
      ) : (
        ''
      )}

      {/* Slideshow */}
      {sliderImages.length > 0 ? (
        <div className="pb-xlarge">
          <Slider {...sliderSettings}>{sliderImages}</Slider>
        </div>
      ) : (
        <></>
      )}

      {/* Main content */}
      <main id="content">
        <div className="grid-wrapper">
          <div className="grid-row clearfix">
            <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
              <div className="copy">
                {/* Add a H1 for SEO if the banner is hidden */}
                {hideHeader && title && <h1 className="hide-visually">{title}</h1>}

                {/* Show an Edit link for Gatsby Preview environments */}
                <AdminInfo status={isLive} moderationState={state} path={path} />
                <DevAids node={thisPage} pageLinks={true} />
                <SiteWideMessageBlock
                  callingPark={park}
                  nodes={data.allBlockContentSiteWideMessage}
                />

                <MessageSomePagesBlock
                  nodeID={nodeID}
                  nodes={data.allBlockContentMessageSomePages}
                  callingPark={park}
                />

                {thisPage.body?.processed ? (
                  <div
                    className="body-content"
                    dangerouslySetInnerHTML={{
                      __html: thisPage.body?.processed,
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Paragraphs */}
        {paragraphs}
      </main>

      {/* Information box */}
      {infobox !== false && infobox}

      {/* Mailchimp Subscribe forms */}
      <MailChimpOptInFormBlocks
        nodes={data.allBlockContentMailchimpOptInForm}
        pagePath={pageContext.alias}
      />

      {/* Tile images on some pages Block */}
      <TileImagesSomePagesBlocks
        nodes={data.allBlockContentTileImagesSomePages}
        pagePath={pageContext.alias}
      />
    </>
  );
}

// This queries pages by their Drupal ID (UUID), not their Node ID
export const query = graphql`
  query ($park: String!, $drupalId: String!) {
    nodePlace(drupal_id: { eq: $drupalId }) {
      id
      drupal_id
      drupal_internal__nid
      title
      created
      changed
      moderation_state
      status
      body {
        processed
        summary
      }
      path {
        alias
      }
      field_hero_block_title {
        value
      }
      field_hide_header_breadcrumbs
      field_legacy_url
      field_place_type {
        drupal_internal__target_id
      }
      field_sensitive_site
      relationships {
        field_hero {
          ...MediaDataHeroImage
        }
        field_place_type {
          name
        }
        field_tile_image {
          ...MediaDataTileImage
        }
        paragraphs: field_paragraphs {
          type: __typename
          ...ParagraphAccordionContainer
          ...ParagraphButtonsContainer
          ...ParagraphDownloadsList
          # ...ParagraphEmbedIframe
          ...ParagraphEmbedMap
          # ...ParagraphEmbedScript
          ...ParagraphEmbedVideo
          ...ParagraphFigure
          ...ParagraphGalleryGrid
          # ...ParagraphFeedList
          ...ParagraphHeroImageSection
          ...ParagraphQuoteWithImage
          ...ParagraphSectionHeadingLinks
          # ...ParagraphPeople
          ...ParagraphText
          ...ParagraphTileImages
        }
        infobox: field_information_widget {
          ...InformationBoxMarinePark
          ...InformationBoxPlace
          ...InformationBoxShop
          ...InformationBoxWalk
          ...InformationBoxWaterfall
        }
      }
    }
    allBlockContentSiteWideMessage(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...SiteWideMessageBlockQuery
    }
    allBlockContentMessageSomePages(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...MessageSomePagesBlockQuery
    }
    allBlockContentTileImagesSomePages(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...TileImagesSomePagesBlockQuery
    }
    blockContentSocialMedia {
      ...SocialMediaLinksBlockQuery
    }
    allBlockContentMailchimpOptInForm(
      filter: {
        field_site_for_block: { drupal_internal__target_id: { eq: $park } }
        field_enabled: { eq: true }
      }
    ) {
      ...MailChimpOptInFormBlockQuery
    }
  }
`;
