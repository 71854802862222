import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import {
  InformationBox,
  InfoboxColumn,
  InfoboxSection,
  featureList,
  getLocationStatus,
  list,
} from './information-box';
import { stripUrlParkPrefix } from '../../functions/common';
import Link from '../elements/link';
import Iframe from '../map/Iframe';

const site = process.env.GATSBY_PARK;

export const InfoboxWaterfall = (query) => {
  // useMemo  to only run functions once
  const accessibility = useMemo(
      () =>
        featureList(
          query.relationships.field_site_accessibility,
          'relationships.field_access_feature.name',
          'true',
          false
        ),
      []
    ),
    coords =
      (query.field_latitude &&
        query.field_longitude &&
        `https://www.google.com/maps/search/?api=1&query=${query.field_latitude},${query.field_longitude}`) ||
      null,
    openStatus = getLocationStatus(
      query.field_current_open,
      query.relationships?.field_site_accessibility_kar?.field_kar_status
    ),
    additionalComments =
      query.relationships?.field_site_accessibility_kar?.field_kar_additional_comments?.processed ||
      null,
    regions = useMemo(() => list(query.relationships.field_region, 'title', true), []);

  let sourceDoc =
    isNaN(query.field_latitude) || isNaN(query.field_longitude)
      ? ``
      : `<div id="infomap"></div>
    <script src="/js/jscommon.js"></script>
    <script src="https://cdn.maptiler.com/mapbox-gl-js/v1.13.2/mapbox-gl.js"></script>
    <link href="https://cdn.maptiler.com/mapbox-gl-js/v1.13.2/mapbox-gl.css" rel="stylesheet"/>
    <link href="/js/mapbox-style.css" rel="stylesheet"/>
    <script src="/js/map-init-infobox-component.js?centre&lat=${query.field_latitude}&long=${query.field_longitude}&zoom=7&park=${site}"></script>`;

  return (
    <InformationBox>
      {/* Column 1 */}
      <InfoboxColumn>
        {site === 'knp' && (
          <p className="pb-xsmall">
            This site is currently <strong>{openStatus}</strong>
          </p>
        )}
        {additionalComments && (
          <div
            className="pb-small info-pullout__text"
            dangerouslySetInnerHTML={{ __html: additionalComments }}
          />
        )}
        {/* Only render the 'Forecast to re-open' field if the site is deemed to be
        closed, to avoid mixed messages appearing */}
        {/* {openStatus === 'closed' && query.field_forecast_to_re_open && (
          <InfoboxSection sectionTitle="Forecast to reopen ">
            <div dangerouslySetInnerHTML={{ __html: query.field_forecast_to_re_open }} />
          </InfoboxSection>
        )} */}
        {accessibility && (
          <InfoboxSection sectionTitle="Site accessibility">{accessibility}</InfoboxSection>
        )}

        {/* Adds KAR link for knp locations only */}
        {site === 'knp' && (
          <p className="pb-small">
            See the <Link to="/access/">Access report</Link> for more details.
          </p>
        )}
        {regions.length > 0 && (
          <InfoboxSection sectionTitle="Region">
            <ul>{regions}</ul>
          </InfoboxSection>
        )}
      </InfoboxColumn>

      {/* Column 2 */}
      <InfoboxColumn>
        {query.field_waterfall_height && (
          <InfoboxSection sectionTitle="Waterfall height">
            {`${query.field_waterfall_height} m`}
          </InfoboxSection>
        )}
        {query.relationships.field_nearest_campground !== null && (
          <InfoboxSection sectionTitle="Nearest campground">
            <Link
              to={stripUrlParkPrefix(query.relationships.field_nearest_campground?.path?.alias)}
            >
              {query.relationships.field_nearest_campground?.title}
            </Link>
          </InfoboxSection>
        )}
        {query.relationships.field_nearest_hotel !== null && (
          <InfoboxSection sectionTitle="Nearest hotel">
            <Link to={stripUrlParkPrefix(query.relationships.field_nearest_hotel?.path?.alias)}>
              {query.relationships.field_nearest_hotel?.title}
            </Link>
          </InfoboxSection>
        )}
      </InfoboxColumn>

      {/* Column 3 */}
      <InfoboxColumn>
        {coords && (
          <InfoboxSection sectionTitle="Location">
            {/* TODO: Add in map link for KNP: <Link to={}>Show on map</Link> */}
            <Link to={coords}>Open in Google Maps</Link>
          </InfoboxSection>
        )}
      </InfoboxColumn>
      <InfoboxColumn className="map-pullout">
        <button className="map-pullout__map-tab infomapclick">
          <div className="map-pullout__map-tab-inner">
            <span aria-hidden="true">
              <svg width="21" height="29" viewBox="0 0 21 29" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <path d="M10.5.5c2.82 0 5.235 1.05 7.248 3.15 2.012 2.1 3.019 4.628 3.019 7.583 0 1.498-.302 3.087-.904 4.77-.603 1.681-1.332 3.183-2.188 4.505a56.813 56.813 0 0 1-2.567 3.66c-.855 1.119-1.584 1.98-2.187 2.582l-.904.904c-.642.564-1.172.841-1.59.831-.418-.01-.928-.286-1.531-.83a58.014 58.014 0 0 1-.934-.963c-.388-.409-1.084-1.235-2.085-2.48a35.13 35.13 0 0 1-2.64-3.733c-.758-1.244-1.448-2.722-2.07-4.433-.623-1.711-.934-3.315-.934-4.813 0-2.955 1.007-5.483 3.02-7.583C5.264 1.55 7.68.5 10.5.5zm0 15.108c1.342 0 2.489-.471 3.442-1.414.952-.943 1.429-2.086 1.429-3.427 0-1.342-.477-2.49-1.43-3.442-.952-.953-2.1-1.43-3.441-1.43-1.342 0-2.484.477-3.427 1.43-.943.953-1.415 2.1-1.415 3.442 0 1.341.472 2.484 1.415 3.427.943.943 2.085 1.414 3.427 1.414z"></path>
                </g>
              </svg>
            </span>
            <em>Map</em>
          </div>
        </button>
        <button className="map-pullout__map-tab--mobile infomapclick">
          <div className="map-pullout__map-tab-inner">
            <span aria-hidden="true">
              <svg width="21" height="29" viewBox="0 0 21 29" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <path d="M10.5.5c2.82 0 5.235 1.05 7.248 3.15 2.012 2.1 3.019 4.628 3.019 7.583 0 1.498-.302 3.087-.904 4.77-.603 1.681-1.332 3.183-2.188 4.505a56.813 56.813 0 0 1-2.567 3.66c-.855 1.119-1.584 1.98-2.187 2.582l-.904.904c-.642.564-1.172.841-1.59.831-.418-.01-.928-.286-1.531-.83a58.014 58.014 0 0 1-.934-.963c-.388-.409-1.084-1.235-2.085-2.48a35.13 35.13 0 0 1-2.64-3.733c-.758-1.244-1.448-2.722-2.07-4.433-.623-1.711-.934-3.315-.934-4.813 0-2.955 1.007-5.483 3.02-7.583C5.264 1.55 7.68.5 10.5.5zm0 15.108c1.342 0 2.489-.471 3.442-1.414.952-.943 1.429-2.086 1.429-3.427 0-1.342-.477-2.49-1.43-3.442-.952-.953-2.1-1.43-3.441-1.43-1.342 0-2.484.477-3.427 1.43-.943.953-1.415 2.1-1.415 3.442 0 1.341.472 2.484 1.415 3.427.943.943 2.085 1.414 3.427 1.414z"></path>
                </g>
              </svg>
            </span>
            <em>Map</em>
          </div>
        </button>
        <div className="map-embed map-pullout__map">
          {
            <Iframe
              content={sourceDoc}
              title="Map"
              style={`width:100%; height:100%; margin:0; padding:0;`}
            />
          }
        </div>
      </InfoboxColumn>
    </InformationBox>
  );
};

export const query = graphql`
  fragment InformationBoxWaterfall on paragraph__information_waterfall {
    id
    drupal_id
    internal {
      type
    }
    field_current_open
    field_forecast_to_re_open
    field_latitude
    field_longitude
    field_waterfall_height
    paragraph_type {
      drupal_internal__target_id
    }
    relationships {
      field_region {
        title
        path {
          alias
        }
      }
      field_nearest_campground {
        title
        path {
          alias
        }
      }
      field_nearest_hotel {
        title
        path {
          alias
        }
      }
      field_site_accessibility {
        id
        field_availability
        relationships {
          field_access_feature {
            name
            path {
              alias
            }
          }
        }
      }
      field_site_accessibility_kar {
        field_kar_additional_comments {
          processed
          value
        }
        field_kar_status
      }
    }
  }
`;
